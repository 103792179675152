<template>
  <div class="card" style="display: flex; flex-direction: column; margin: 10px">
    <div style="display: flex; flex-direction: column">
      <div class="card-header"></div>
    </div>
    <div v-if="dropTasks?.length">
      <div v-for="item of dropTasks">
        <SytelineOrderItem
          :item="item"
          :selectedDriver="selectedDriver"
          :pickupLocationId="props.pickupLocationId"
        />
      </div>
    </div>

    <div
      class="ml-1 mt-1"
      style="color: green; font-weight: 500; font-size: 11px"
    >
      Select location and driver to add as stop.
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { getFullNameAny } from "@/services/UserUtil";
import { useStore } from "vuex";
import SytelineOrderItem from "../SytelineOrderItem.vue";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  pickupLocationId: {
    type: Number,
    required: false,
  },
});

const $store = useStore();

const dropTasks = computed(() => {
  return props.value.sytelineOrderItems;
});

const selectedDriver = computed(() => {
  return $store.state.selectedDriver
    ? `${getFullNameAny($store.state.selectedDriver.user)}`
    : false;
});

</script>

<style>
.labelStyle {
  font-weight: 600;
}

.line-item-assignments {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  padding: 0.5em;
}

.line-item-assignment:hover {
  background-color: aliceblue;
}
</style>
