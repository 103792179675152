<template>
  <div
    style="cursor: grab; padding: 10px"
    :class="orderComplete ? 'card success' : 'card'"
  >
    <div style="display: flex; justify-content: flex-end" v-if="orderComplete">
      <span class="mr-1">Delivered {{ amountDelivered }} on</span>
      <span class="mr-1">{{ createdOn }}</span>
      <i class="mdi mdi-check-circle" aria-hidden="true"></i>
    </div>

    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div>
        <span class="is-size-6 has-text-weight-bold mr-1">{{
          stopInfo.stopOrder
        }}</span>
        <span class="is-size-7 has-text-weight-bold">
          Order #: {{ orderNumber }}</span
        >
      </div>
      <span class="is-size-7 has-text-weight-medium">{{
        pickupLocationAddress
      }}</span>
    </div>
    <div style="display: flex; gap: 0 0.5rem; margin: 0.5rem 0">
      <div style="flex: 1">
        <div
          style="display: flex; align-items: center"
          v-for="dropTask of dropTasks"
          :key="dropTask.id"
        >
          <div class="mr-2">
            <span>Qty: </span>
            <span style="font-weight: 400">{{ dropTask.amount }}</span>
          </div>
          <div class="mr-2">
            <span>Item: </span>
            <span style="font-weight: 400">
              {{ dropTask.item.sytelineItem }}
            </span>
          </div>
        </div>
      </div>
      <PanToButton :location="stopInfo.location" />
      <icon-button
        v-if="!orderComplete"
        type="is-warning"
        icon="delete"
        @click="emitRemove(stopInfo)"
      />
    </div>
    <ToggleNotes
      title="Driver Notes"
      :data="stopInfo.notes"
      :editable="true"
      @saveEvent="(e) => saveNotesHandler(e, stopInfo)"
    />
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";
import PanToButton from "./PanToButton.vue";
import ToggleNotes from "./ToggleNotes.vue";

export default {
  name: "RouteStopDropRequest",
  components: {
    IconButton,
    PanToButton,
    ToggleNotes,
  },
  props: {
    stopInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    orderNumber() {
      return this.dropTasks[0].sytelineOrderId
        ? this.dropTasks[0].sytelineOrderId
        : "n/a";
    },
    pickupLocationAddress() {
      const pl = this.stopInfo.pickupLocation;
      return `${pl.addStreet} ${pl.addCity}, ${pl.addState} ${pl.addPostalCode}`;
    },
    orderComplete() {
      return this.dropTasks.every((dt) => {
        return dt.info !== null;
      });
    },
    dropTasks() {
      return this.stopInfo.dropTasks;
    },
    createdOn() {
      return new Date(this.dropTasks[0].createdOn).toLocaleString();
    },
    amountDelivered() {
      return this.dropTasks.reduce((total, dt) => total + (dt.amount || 0), 0);
    },
  },
  mounted() {},
  emits: ["remove"],
  methods: {
    emitRemove(stop) {
      this.$emit("remove", stop);
    },
    async saveNotesHandler(notes, stop) {
      const { routeId, rowPointer } = stop;

      await this.$api.putDriverStop(routeId, rowPointer, { notes });
      this.$store.dispatch("getDrivers");
    },
  },
};
</script>

<style lang="css" scoped>
.success {
  color: var(--successDark);
  background: var(--successLight);
}
</style>
