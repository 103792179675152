<template>
  <div
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 5px;
    "
  >
    <div><span class="labelStyle">Item: </span>{{ props.item.item }}</div>
    <div>
      <span class="labelStyle">Shipped: </span>{{ props.item.qtyShipped }}
    </div>
    <div>
      <span class="labelStyle">Ordered: </span>{{ props.item.qtyOrdered }}
    </div>
    <div><span class="labelStyle">LTS: </span>{{ lts }}</div>
  </div>
  <div v-if="selectedDriver">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 5px 5px;
      "
    >
      <div style="display: flex; flex-direction: row">
        <input
          type="number"
          min="0"
          style="width: 50px; margin-right: 10px"
          placeholder="Qty"
          v-model="data.quantity"
        />
        <b-button size="is-small" @click="addStopToDriverHandler">
          Some for {{ selectedDriver }}
        </b-button>
      </div>
      <div>
        <b-button size="is-small" @click="addAllStopToDriverHandler"> All for {{ selectedDriver }} </b-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  selectedDriver: {
    type: [String, Boolean],
    required: true,
  },
  pickupLocationId: {
    type: Number,
    required: false,
  },
});
const $store = useStore();

const lts = computed(() => {
  return props.item.qtyOrdered - props.item.qtyShipped;
});

const data = reactive({
  loading: false,
  quantity: 0,
});

async function addStopToDriverHandler() {
  if (!props.item) {
    return;
  }
  if (data.quantity === 0) {
    return;
  }
  try {
    data.loading = true;

    const { orderNumber, lineNumber } = props.item;

    const parsedRequestData = {
      orderNumber: orderNumber,
      lineNumber: parseInt(lineNumber),
      quantity: parseInt(data.quantity),
      pickupLocationId: props.pickupLocationId,
      item: props.item.item,
    };
    await $store.dispatch("addDropRequestStopToDriver", parsedRequestData);
  } finally {
    data.quantity = 0;
    data.loading = false;
  }
  //TODO: REFRESH?
}

async function addAllStopToDriverHandler() {
  const { orderNumber, lineNumber } = props.item;

  try {
    data.loading = true;
    const parsedRequestData = {
      orderNumber: orderNumber,
      lineNumber: parseInt(lineNumber),
      quantity: parseInt(props.item.qtyOrdered),
      pickupLocationId: props.pickupLocationId,
      item: props.item.item,
    };
    await $store.dispatch("addDropRequestStopToDriver", parsedRequestData);
  } finally {
    data.loading = false;
  }
  //TODO: REFRESH?
}

async function deleteDropTask(dropTask) {
  await $store.dispatch("deleteDropTask", {
    routeRowPointer: dropTask.driverStop.route.rowPointer,
    dropTaskRowPointer: dropTask.rowPointer,
  });
}
</script>

<style scoped>
.container {
}
</style>
